<template>
  <div class="flex flex-col gap-4 py-4 w-full h-full px-3 overflow-x-hidden relative">
    <h1 class="text-xl border-b text-left font-bold flex text-darkPurple">Time Tracking</h1>
    <c-card class="">
      <Tabs
        class="mt-2 mx-2"
        :tabs="tabsItems"
        :active-tab="currentTab"
        @currentTab="onChangeCurrentTab($event)"
        :border="true"
      />
    </c-card>
    <div class="h-full">
      <AutomaticClockInOut v-if="currentTab === 'Automatic Clock In/Out'" />
      <ClockInOutExclusion v-if="currentTab === 'Clock In/Out Exclusion'" />
    </div>
  </div>
</template>

<script>
import Tabs from "@scelloo/cloudenly-ui/src/components/tab"
import CCard from "@scelloo/cloudenly-ui/src/components/card"

export default {

  components: {
    Tabs,
    CCard,
    AutomaticClockInOut: () => import('./AutomaticClockInOut'),
    ClockInOutExclusion: () => import('./ClockInOutExclusion'),
  },

  data() {
    return {
      currentTab: this.$route.query.currentTab || "Automatic Clock In/Out",
      tabsItems: ["Automatic Clock In/Out", "Clock In/Out Exclusion"]
    }
  },
  methods: {
    onChangeCurrentTab(currentTab) {
      if (this.currentTab !== currentTab) {
        this.currentTab = currentTab
        this.$router.push({ query: { currentTab } })
      }
    }
  },
};
</script>
